.header-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* min-width: 1920px; */
  width: 100%;
  height: 83px;
  padding-right: 5%;
  padding-left: 5%;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 20px rgba(0, 150, 213, 0.25);
  /* border: 2px solid red; */
}


.main-navigation {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: row;
  /* border: 2px solid green; */
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* align-items: center; */
  /* justify-content: flex-start; */
}



.platform-section {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: row;
  width: 20%;
  /* border: 2px solid red; */
  /* align-items: flex-start; */
  /* justify-content: flex-start; */
}


.platform-title1 {
  /* flex: 0 0 auto; */
  color: rgba(0, 0, 0, 1);
}

.whistle-os-heading {
  font: 700 36px Lato;
  color: rgba(6, 55, 103, 1);
}

.whistle-os-title1 {
  font: 700 36px Lato;
  color: rgba(0, 150, 213, 1);
}

.platform-products1 {
  display: flex;
  width: 50%;
  justify-content: space-between;
  /* flex: 0 0 auto; */
  flex-direction: row;
  align-items: center;
  /* border: 2px solid blue; */
  /* justify-content: flex-start; */
  /* margin-left: 60px; */
}

.platform-title {
  flex: 0 0 auto;
  font: 400 20px Outfit;
  color: rgba(17, 17, 17, 1);
}

.active-status {
  font: 400 20px Outfit;
  color: rgba(0, 150, 213, 1);

}

.platform-products {
  flex: 0 0 auto;
  margin-left: 40px;
  font: 400 20px Roboto;
  color: rgba(17, 17, 17, 1);
}

.navigation-bar {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: row;
  /* align-items: center; */
  /* border: 2px solid red; */
  width: 80%;
  justify-content: space-between;
  /* justify-content: flex-start; */
  /* margin-left: 10px; */
}

@media screen and (max-width:769px) {
  .navigation-bar{
    display: none;
  }
  
}

.svg-container {
  display: flex;
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  color: rgba(17, 17, 17, 1);
}

.navigation-bar1 {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  justify-content: flex-start;
  margin-left: 40px;
}

.customer-resources {
  flex: 0 0 auto;
  font: 400 20px Roboto;
  color: rgba(0, 0, 0, 1);
}


.primary-button-talk {
  box-sizing: border-box;
  display: flex;
  /* flex: 0 0 auto; */
  width: 112px;
  min-width: 112px;
  justify-content: center;
  align-items: center;
  height: 41px;
  /* margin-left: 320px; */
  font: 500 18px Roboto;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  background: rgba(0, 150, 213, 1);
  border: none;
  border-radius: 27px;
  /* align-self: flex-end; */
  /* justify-self: flex-end !important; */
  margin-left: auto;
}

a {
  text-decoration: none;
  /* color: #333333; */
}

.drop-downcontainer {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 5px;
}

.mobile-drop-downcontainer{
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  gap: 5px;
  width: 100%;
}
.mobile-drop-downcontainer img{
  width: 18px;
  object-fit: contain;

}
.drop-downcontainer img {
  width: 18px;
  object-fit: contain;
}

.dropdown {
  text-decoration: none;
  /* border-top: 3px solid #0096d5; */
  color: #333333;
  padding: 15px 10px;
  border-radius: 4px;
  display: flex;
  position: absolute;
  top: 10vh;
  flex-direction: column;
  /* background-color: #1b92c5;   */
  background-color: #F5F7F9;
  gap: 10px;
  /* margin-left: %; */
  /* font-family: "Product-Sans-Regular"; */
}

.mobile-dropdown{
  text-decoration: none;
  color: #333333;
  padding: 15px 10px;
  border-radius: 4px;
  display: flex;
  /* position: absolute; */
  top: 10%;
  flex-direction: column;
  /* background-color: #1b92c5;   */
  background-color: #F5F7F9;
  gap: 10px;
  width: 80% !important;

}

.mobile-dropdown a {
  /* color:; */
  font: 400 20px Outfit;
  color: rgba(0, 150, 213, 1);
  text-decoration: none;
}

/* .dropdown {
  display: none;
  text-decoration: none;
} */

.dropdown a {
  /* color:; */
  font: 400 20px Outfit;
  color: rgba(0, 150, 213, 1);
  text-decoration: none;
}

.mobilenavItems {
  list-style: none;
  align-items: center;
  margin: 0px;
  gap: 30px;
  /* font-family: "Poopins-Medium"; */
  position: absolute;
  right: 0px;
  height: 92vh;
  top: 10vh;
  /* top: 10vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  transform: translateX(100%);
  transition: transform 0.5s ease-in;
  background-color: white;
  z-index: 9999;
  /* border: 2px solid red; */
  /* pointer-events: none; */
  /* position: fixed; */
}

.mobilenavItems.active {

  transform: translateX(0%);
}

.navItems li a {
  text-decoration: none;
  color: #d3d4d4;
  /* font-weight: 200; */
  font-family: "Poopins-Regular";
  font-size: var(--fs-300);
}



.mobilenavItems li a {
  text-decoration: none;
  color: #d3d4d4;
  /* font-weight: 200; */
  font-family: "Poopins-Regular";
  font-size: var(--fs-300);
}


.hamburger {
  width: 40px;
  position: relative;
  cursor: pointer;
  line-height: 10px;
  z-index: 1500;
  height: 30px;

}

@media screen and (min-width:996px) {
  .hamburger {
      display: none;
  }
}

.hamburger .line {
  width: 80%;
  height: 3px;
  background-color:rgba(0, 150, 213, 1);;
  position: absolute;
  display: block;
  right: 0;
  transition: all 650ms;
}

.hamburger .line:nth-child(1) {
  top: 0;
}

.hamburger .line:nth-child(2) {
  top: 50%;
  margin-top: -1px;
  width: 50%;
}

.hamburger .line:nth-child(3) {
  top: 100%;
  margin-top: -2px;
  width: 20%;
}

.hamburger.active .line:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
  width: 70%;
}

.hamburger.active .line:nth-child(2) {
  opacity: 0;
}

.hamburger.active .line:nth-child(3) {
  transform: translateY(-20px) rotate(-45deg);
  width: 70%;
}