.meeting-booking-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 60px 40px 38px;
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  width: 100%;
  /* border: 2px solid red; */
}
.book-meeting-title {
  /* flex: 0 0 auto; */
  font: 700 36px Outfit;
  color: rgba(6, 55, 103, 1);
}

@media screen and (max-width:769px) {
  .meeting-booking-container{
    padding: 20px;

  }
  .book-meeting-title{
    font: 700 34px Outfit;

  }
  
}