.main-container2 {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  /* align-items: stretch; */
  /* align-self: stretch; */
  justify-content: flex-start;
  /* margin-top: -87px; */
}
.hero-image {
  box-sizing: border-box;
  display: block;
  flex: 0 0 auto;
  /* width: 1920px; */
  max-width: initial;
  /* height: 307px; */
  width: 100%;
  border: none;
  object-fit: contain;
}
