.page-content-main-container {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  align-items: center;
  /* align-items: stretch; */
  /* justify-content: flex-start; */
  padding-top: 100px;
  /* padding-bottom: 100px; */
  background: rgba(12, 40, 68, 1);
}
.main-container1 {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  width: 80%;
  /* align-items: stretch; */
  /* justify-content: flex-start; */
  /* padding-right: 360px; */
  /* padding-left: 360px; */
}
.platform-title {
  /* flex: 0 0 auto; */
  color: rgba(0, 0, 0, 1);
}
.whistle-os-title2 {
  font: 700 36px Lato;
  color: rgba(255, 255, 255, 1);
}
.whistle-os-title1 {
  font: 700 36px Lato;
  color: rgba(0, 150, 213, 1);
}
.service-section {
  box-sizing: border-box;
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: row;
  gap: 8px;
  /* align-items: stretch; */
  justify-content: space-between;
  width: 100%;
  margin-top: 2%;
  /* border: 2px solid red; */
  /* height: 115px; */
  /* padding-right: 14px; */
  /* margin-top: 20px; */
}
.subscription-container {
  display: flex;
  /* flex: 1 0 auto; */
  flex-direction: column;
  /* align-items: stretch; */
  /* justify-content: center; */
  width: 50%;
  /* border: 2px solid red; */
}
.subscribe-title {
  flex: 0 0 auto;
  font: 600 26px Outfit;
  color: rgba(255, 255, 255, 1);
}
.subscribe-container {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  height: 50px;
  padding-left: 20px;
  margin-top: 20px;
  font: 400 16px Outfit;
  color: rgba(0, 0, 0, 1);
  background: rgba(232, 234, 241, 1);
  border: none;
  border-radius: 50px;
  opacity: 0.6;
}
.subscription-input {
  box-sizing: border-box;
  width: 100%;
  font: 400 16px Outfit;
  background: transparent;
  border: none;
  outline: none;
  opacity: 0.6;
}
.input-style-e93d0fb::placeholder {
  color: rgba(0, 0, 0, 1);
}
.subscription-section {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: row;
  /* align-items: stretch; */
  justify-content: flex-start;
  /* border: 2px solid red; */
  /* margin-top: 4%; */
}
.form-container1 {
  box-sizing: border-box;
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  /* border: 2px solid green; */
  /* align-items: stretch; */
  /* justify-content: center; */
  /* gap: 10px; */
  /* width: 44.08%; */
  /* padding: 6px 15px 37px; */
}
.outfit-text {
  /* flex: 0 0 auto; */
  font: 400 16px Outfit;
  color: rgba(255, 255, 255, 1);
  /* border: 2px solid red; */
  /* width: 100%; */
}
.privacy-policy-link {
  font: 400 16px Outfit;
  text-decoration-line: underline;
}
.subscribe-button {
  box-sizing: border-box;
  display: block;
  flex: 0 0 auto;
  width: 226px;
  min-width: 226px;
  height: 59px;
  margin-top: 30px;
  font: 600 26px Outfit;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  background: rgba(0, 150, 213, 1);
  border: none;
  border-radius: 50px;
}
.privacy-info {
  /* flex: 0 0 auto; */
  /* padding-right: 48px; */
  /* padding-left: 687px; */
  display: flex;
  margin-top: 23px;
  /* border: 2px solid red; */
  width: 50%;
  align-self: flex-end;
}
.policy-links-container {
  display: flex;
  flex-direction: row;
  gap: 19px;
  align-items: center;
  justify-content: flex-start;
  /* margin-top: 5%; */
}
.content-wrapper {
  /* padding-right: 39px; */
  /* padding-left: 171px; */
  /* margin-top: 30px; */
  display: flex;
  width: 60%;
  justify-content: space-between;
  margin-top: 2%;
  /* border: 2px solid red; */
}
.image-container6 {
  /* box-sizing: border-box; */
  /* display: block; */
  /* width: 50% !important; */
  /* max-width: initial; */
  object-fit: contain;
  /* height: 41px; */
  width: 20px;
}

@media screen and (max-width:769px) {
  .page-content-main-container{
    padding-top: 30px;
    /* padding-bottom: 30px; */
  }
  .main-container1 {
    /* border: 2px solid red; */
    width: 90%;
  }
  .platform-title {
    /* flex: 0 0 auto; */
    color: rgba(0, 0, 0, 1);
    /* border: 2px solid red; */
    text-align: left;
  }
  .service-section{
    flex-wrap: wrap;
  }
  .subscription-container{
    width: 100%;
  }
  .form-container1{
    width: 100%;
    /* border: 2px solid green; */
  }
  .policy-links-container {
    margin-top: 10px;
    /* overflow: hidden; */
    flex-wrap: wrap;

  }
  
}