.main-container {
  width: 100%;
  /* overflow: hidden; */
  position: relative;
  /* border: 2px solid red; */
}

.delivery-operations-section {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  /* align-items: flex-start; */
  /* padding-left: 10%; */
  /* padding-right: 10%; */
  justify-content: center;
  align-items: center;
  /* border: 2px solid green; */
  /* justify-content: flex-end; */
  /* padding: 46px 212px 72px; */
}

.sub-container {
  width: 85%;
  display: flex;
  align-items: center;
  margin-top: 5%;
  justify-content: space-between;
}

@media screen and (max-width:769px) {
  .sub-container {
    flex-direction: column;
    /* border: 2px solid red; */
  }

}

.delivery-operations-section1 {
  box-sizing: border-box;
  display: flex;
  /* flex: 0 1 auto; */
  flex-direction: column;
  /* align-items: stretch; */
  justify-content: center;
  width: 50%;

  /* max-width: 629px; */
  /* padding-top: 89px; */
  /* padding-bottom: 45px; */
  /* margin-right: 25px; */
}

@media screen and (max-width:769px) {
  .delivery-operations-section1 {
    width: 100% !important;
    /* border: 2px solid red; */
  }

}

@media screen and (max-width:769px) {
  .delivery-operations-message {
    /* flex: 0 0 auto; */
    font: 400 26px Outfit !important;
    /* border: 2px solid red; */
    /* color: rgba(5, 71, 121, 1); */
    /* text-align: left; */
    /* padding: 0px; */
    /* margin: 0px; */
  }

  .delivery-operations-title1 {
    font: 700 38px Outfit !important;
    text-align: left;
  }

  .delivery-operations-title {
    font: 700 38px Outfit !important;
    color: rgba(0, 150, 213, 1);
    text-align: left;
  }

  .delivery-message {
    font: 400 22px Outfit !important;
    /* width: 100%; */
    /* color: rgba(17, 17, 17, 1); */
  }

  .delivery-buttons {
    display: flex;
    /* flex: 0 0 auto; */
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px !important;
  }

  .primary-button1 {
    /* width: 120px !important;
    min-width: 120px !important;
    height: 50px !important;
    font: 600 20px Outfit !important;
    border-radius: 30px !important; */
    width: 120px !important;
    min-width: 120px !important;
    height: 40px !important;
    font: 600 16px Outfit !important;
  }

  .primary-button {
    width: 120px !important;
    min-width: 120px !important;
    height: 40px !important;
    font: 600 16px Outfit !important;
  }

  .delivery-image {
    /* flex: 0 0 auto; */
    width: 100% !important;
  }


}

.delivery-operations-message {
  /* flex: 0 0 auto; */
  font: 400 40px/54px Outfit;
  color: rgba(5, 71, 121, 1);
  text-align: left;
  padding: 0px;
  margin: 0px;
}

.delivery-operations-title1 {
  font: 700 52px/54px Outfit;
  text-align: left;
}

.delivery-operations-title {
  font: 700 52px/54px Outfit;
  color: rgba(0, 150, 213, 1);
  text-align: left;
}

.delivery-message {
  font: 400 28px/54px Outfit;
  color: rgba(17, 17, 17, 1);
  text-align: left;
  padding: 0px;
  margin: 0px;
  /* border: 2px solid red; */
  line-height: 1.3;
}

.delivery-buttons {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 50px;
}

.primary-button1 {
  box-sizing: border-box;
  display: block;
  flex: 0 0 auto;
  width: 160px;
  min-width: 160px;
  height: 67px;
  font: 600 22px Outfit;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  background: rgba(0, 150, 213, 1);
  border: none;
  border-radius: 40px;
}

.primary-button {
  box-sizing: border-box;
  display: block;
  flex: 0 0 auto;
  width: 160px;
  min-width: 160px;
  height: 67px;
  margin-left: 30px;
  font: 600 22px Outfit;
  color: rgba(0, 150, 213, 1);
  cursor: pointer;
  background: transparent;
  border: 1px solid rgba(0, 150, 213, 1);
  border-radius: 40px;
}

.delivery-image {
  /* flex: 0 0 auto; */
  width: 45%;
}





.image-container-home {
  box-sizing: border-box;
  display: block;
  /* width: 754px; */
  /* max-width: initial; */
  width: 100%;
  /* height: 600px; */
  border: none;
  object-fit: contain;
}



.delivery-experience {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 170px;
  align-items: center;
  justify-content: flex-start;
  max-width: 1920px;
  background: rgba(255, 255, 255, 1);
}

.delivery-experience-section6 {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  align-items: center;
  /* align-self: stretch; */
  justify-content: flex-start;
  /* padding-right: 332px; */
  /* padding-left: 328px; */
  margin-top: 30px;
  width: 100%;
}

.platform-title {
  /* flex: 0 0 auto; */
  color: rgba(0, 0, 0, 1);
}

.modular-title {
  font: 600 34px Outfit;
}

.modular-title1 {
  font: 600 34px Outfit;
  color: rgba(0, 0, 0, 1);
}

.fulfillment-buttons {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: row;
  gap: 40px;
  align-items: center;
  /* align-self: stretch; */
  justify-content: space-between;
  /* margin-top: 98px; */
}

.fulfillment-button {
  box-sizing: border-box;
  /* display: block; */
  /* flex: 0 0 auto; */
  width: 180px;
  min-width: 180px;
  height: 60px;
  font: 600 20px Outfit;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  background: rgba(0, 150, 213, 1);
  border: none;
}

.fulfillment-button1 {
  box-sizing: border-box;
  /* display: block; */
  /* flex: 0 0 auto; */
  width: 180px;
  min-width: 180px;
  height: 60px;
  font: 600 20px Outfit;
  color: rgba(17, 17, 17, 1);
  cursor: pointer;
  background: rgba(0, 150, 213, 0.1);
  border: none;
}

.fullfillment-maincontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

}

.fulfillment-section {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin-top: 40px;
  width: 80%;
  /* justify-content: flex-end; */
  /* padding-right: 300px; */
  /* padding-left: 300px; */
  /* margin-top: -70px; */
}

.fulfillment-container {
  box-sizing: border-box;
  display: flex;
  /* flex: 0 1 auto; */
  flex-direction: column;
  gap: 40px;
  /* align-items: stretch; */
  justify-content: flex-start;
  /* max-width: 589px; */
  /* margin-right: 63px; */
}

.fulfillment-heading {
  /* flex: 0 0 auto; */
  font: 700 30px Outfit;
  color: rgba(6, 55, 103, 1);
  text-align: left;
}

.fulfillment-description {
  /* flex: 0 0 auto; */
  font: 400 20px Outfit;
  color: rgba(6, 55, 103, 1);
  text-align: left;
}

.industry-type-container {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.primary-button2 {
  box-sizing: border-box;
  display: block;
  /* flex: 0 0 auto; */
  width: 216px;
  min-width: 216px;
  height: 57px;
  font: 600 20px Outfit;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  background: rgba(0, 150, 213, 1);
  border: none;
  border-radius: 40px;
}

.demo-button {
  box-sizing: border-box;
  display: block;
  flex: 0 0 auto;
  width: 217px;
  min-width: 217px;
  height: 57px;
  margin-left: 35px;
  font: 600 20px Outfit;
  color: rgba(0, 150, 213, 1);
  cursor: pointer;
  background: transparent;
  border: 2px solid rgba(0, 150, 213, 1);
  border-radius: 40px;
}

.fulfillment-image {
  /* flex: 0 0 auto; */
  padding-top: 7px;
  padding-bottom: 6px;
  width: 50%;
}

.image-container1 {
  box-sizing: border-box;
  /* display: block; */
  /* width: 640px; */
  /* max-width: initial; */
  width: 100%;

  /* height: 440px; */
  border: none;
  border-radius: 20px;
  object-fit: contain !important;
  /* height: 100%;     */
}

.fulfillment-content-container {
  display: flex;
  align-items: center;
}

.delivery-experience-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.delivery-experience-transformation {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  width: 80%;
  justify-content: center;
  align-items: center;
  /* justify-content: flex-start; */
  /* padding-right: 373px; */
  /* padding-left: 308px; */
  margin-top: 30px;
}

.outfit-heading {
  /* flex: 0 0 auto; */
  /* padding-right: 68px; */
  /* padding-left: 32px; */
  font: 700 52px Outfit;
  color: rgba(6, 55, 103, 1);
  /* text-align: center; */
}

.transportation-ops-container {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: row;
  gap: 8px;
  align-items: center;
  /* align-self: stretch; */
  justify-content: space-between;
  margin-top: 70px;
  /* border: 2px solid red; */
  width: 100%;
}

.delivery-image {
  flex: 0 0 auto;
}

.transportation-platform {
  display: flex;
  /* flex: 0 1 auto; */
  flex-direction: column;
  width: 45%;
  /* align-items: stretch; */
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.mobility-platform-description {
  /* flex: 0 0 auto; */
  font: 400 24px/35px Outfit;
  color: rgba(6, 55, 103, 1);
  text-align: left;
}

.delivery-experience-section2 {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: row;
  width: 90%;
  /* align-items: stretch; */
  /* align-self: stretch; */
  /* justify-content: flex-start; */
  /* padding-right: 360px; */
  /* padding-left: 360px; */
}

.delivery-experience-section1 {
  box-sizing: border-box;
  flex: 0 0 auto;
  width: 62.67%;
  padding-bottom: 378px;
}

.delivery-experience-heading {
  font: 700 64px Outfit;
  color: rgba(6, 55, 103, 1);
  text-align: left;
}

.delivery-experience-text {
  margin-top: 20px;
  font: 400 22px Outfit;
  color: rgba(6, 55, 103, 1);
  text-align: left;
}

.delivery-expert-section {
  box-sizing: border-box;
  /* flex: 0 0 auto; */
  /* width: 37.33%; */
  padding-right: 15px;
  padding-left: 15px;
  width: 50%;
  /* border: 2px solid red; */
}

.delivery-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 5%;
  padding-bottom: 5%;
}

.turnaround-section {
  flex: 1 1 0;
  padding: 29px 29px 41px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
}

.flex-container-with-icons-and-text {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
}

.svg-container1 {
  display: flex;
  flex: 0 0 auto;
  width: 32px;
  height: 32px;
  color: rgba(0, 150, 213, 1);
}

.turnaround-message {
  margin-top: 39px;
  font: 500 24px/32px Outfit;
  color: rgba(6, 55, 103, 1);
  text-align: left;
}

.operation-executive1 {
  margin-top: 120px;
  font: 300 24px Outfit;
  color: rgba(6, 55, 103, 1);
}

@media screen and (max-width:769px) {
  .delivery-experience-section6{
    /* border: 2px solid red; */
    width: 100%;
  }
  .fulfillment-section{
    width: 95% !important;
    /* border: 2px solid green; */
    flex-direction: row !important;
    gap: 10px !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
  .fulfillment-buttons{
    flex-direction: column !important;
    width: 30% !important;
    /* border: 2px solid red; */
    gap: 20px !important;

  }
  .fulfillment-button{
    width: 100px !important;
    min-width:100px !important;
    height: 40px !important;
    font: 600 16px Outfit !important;

  }
  .fulfillment-button1{
    width: 100px !important;
    min-width: 100px !important;
    height: 40px !important;
    font: 600 16px Outfit !important;

  }
  .fulfillment-content-container{
    width: 100% !important;
    flex-direction: column;
  }
  .fulfillment-container {
    width: 100%;
    gap: 20px !important;
    /* border: 2px solid red; */
  }
  .fulfillment-heading{
    font: 700 22px Outfit !important;

  }
  .fulfillment-description{
    font: 400 16px Outfit !important;

  }
  .primary-button2 {
    box-sizing: border-box;
    display: block;
    /* flex: 0 0 auto; */
    width: 120px !important;
    min-width: 120px !important;
    height: 40px !important;
    font: 600 16px Outfit !important;
  }
  
  .demo-button {
    box-sizing: border-box;
    display: block;
    /* flex: 0 0 auto; */
    width: 120px !important;
    min-width: 120px !important;
    height: 40px !important;
    font: 600 16px Outfit !important;
    margin-left: 0px !important;
  }
  .industry-type-container {
    display: flex;
    /* flex-direction: column !important; */
    /* border: 2px solid yellow; */
    /* width: 100% !important; */
    gap: 20px;
  }
  .fulfillment-image{
    display: none;
  }
  .platform-title{
    text-align: center;
    /* font-size: 20px !important; */
  }
  .modular-title {
    font: 600 26px Outfit !important;
  }
  
  .modular-title1 {
    font: 600 26px Outfit !important;
    /* color: rgba(0, 0, 0, 1); */
  }
  .transportation-ops-container{
    flex-direction: column !important;
    margin-top: 30px;
  }
  .outfit-heading{
    font: 700 32px Outfit !important;
    width: 100% !important;
    /* border: 2px solid red; */
    text-align: left !important;
    padding: 0px !important;

  }
  .delivery-experience-transformation{
    width: 90% !important;
  }
  .transportation-platform{
    width: 90% !important;

  }
  .mobility-platform-description {
    font: 400 20px Outfit !important;
  }
  .delivery-experience-section2{
    flex-direction: column !important;
  }
  .delivery-experience-heading{
    font: 700 38px Outfit !important;
    /* border: 2px solid red; */

  }
  .delivery-experience-section1 {
    box-sizing: border-box;
    /* flex: 0 0 auto; */
    width: 100% !important;
    padding-bottom: 30px !important;
  }
  .delivery-expert-section{
    width: 100% !important;
    /* border: 2px solid red;  */
  }
  
}