.os-benefits-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-width: 1920px; */
  /* padding: 100px 387px 140px 300px; */
  background: rgba(245, 247, 249, 1);
  padding-top: 5%;
  padding-bottom: 5%;
}
.os-content-container{
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.headline {
  /* flex: 0 0 auto; */
  font: 700 34px Outfit;
  color: rgba(0, 0, 0, 1);
}
.industry-section {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  /* align-self: stretch; */
  justify-content: space-between;
  margin-top: 100px;
  width: 100%;
  /* border: 2px solid red; */
  align-items: center;
}
.tech-platform-container1 {
  /* flex: 0 0 auto; */
  padding-top: 6px;
  padding-bottom: 71px;
  width: 50%;
}
.image-container1 {
  box-sizing: border-box;
  display: block;
  /* width: 640px; */
  max-width: initial;
  /* height: 440px; */
  border: none;
  border-radius: 20px;
  object-fit: cover;
  width: 100%;
}
.tech-platform-container {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  /* align-items: stretch; */
  justify-content: flex-start;
  width: 50%;
  /* border: 2px solid red; */
}


@media screen and (max-width:769px) {
  .os-content-container{
    width: 100%;
    /* border: 2px solid red; */
  }
  .headline{
    font: 700 28px Outfit;
    text-align: center;
  }
  .industry-section{
    flex-direction: column;
    margin-top: 50px;

  }
  .tech-platform-container1{
    width: 80%;
    /* border: 2px solid red; */
  }
  .tech-platform-container {
    width: 80%;
  }

  
}