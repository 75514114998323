.industry-type-container2 {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* border: 2px solid red; */
  gap: 20px;
}
/* .delivery-image {
  flex: 0 0 auto;
} */
.image-container-svg {
  box-sizing: border-box;
  display: block;
  width: 10px;
  max-width: initial;
  height: 11px;
}
.industry-type-label {
  flex: 0 0 auto;
  /* margin-left: 20px; */
  font: 400 20px Outfit;
  color: rgba(6, 55, 103, 1);
  /* white-space: pre-wrap; */
}

@media screen and (max-width:769px) {
  .industry-type-label {
    font: 400 18px Outfit;
    
  }
  
}
