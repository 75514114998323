.main-container {
    width: 100%;
    /* border: 2px solid red; */
    overflow: hidden;
}

.delivery-experience-section4 {
    display: flex;
    /* flex: 0 0 auto; */
    flex-direction: column;
    align-items: center;
    /* border: 2px solid blue; */
    width: 100%;
    margin-top: 100px;
    /* gap: 170px; */
    /* align-items: center; */
    /* justify-content: flex-start; */
}

.delivery-operations {
    display: flex;
    /* flex: 0 0 auto; */
    flex-direction: column;
    margin-top: 10% !important;
    /* align-items: center; */
    /* align-self: stretch; */
    /* justify-content: flex-start; */
    /* padding-right: 410px; */
    /* padding-left: 410px; */
}

.delivery-heading {
    flex: 0 0 auto;
    font: 800 46px Outfit;
    color: rgba(6, 55, 103, 1);
    text-align: center;
}

.driver-info-section {
    display: flex;
    /* flex: 0 0 auto; */
    flex-direction: row;
    /* align-items: flex-start; */
    /* justify-content: flex-end; */
    /* padding-right: 200px; */
    /* padding-left: 200px; */
    margin-top: 169px;
    justify-content: center;
    width: 100%;
    /* border: 2px solid red; */
}

.driver-info-main-container {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 2px solid red; */
}

.driver-info-container {
    box-sizing: border-box;
    display: flex;
    /* flex: 0 1 auto; */
    flex-direction: column;
    /* align-items: stretch; */
    /* justify-content: flex-start; */
    /* max-width: 589px; */
    padding-top: 33px;
    padding-bottom: 12px;
    /* margin-right: 58px; */
    width: 50%;
    /* border: 2px solid yellow; */
}

.app-title {
    flex: 0 0 auto;
    font: 700 52px Outfit;
    color: rgba(6, 55, 103, 1);
    text-align: left;
}

ol{
    list-style-type:disc;
}

.driver-info-list1 {
    /* flex: 0 0 auto; */
    /* padding: 0; */
    margin: 32px 0 0;
    font: 400 20px Outfit;
    color: rgba(6, 55, 103, 1);
    /* text-align: left; */
    list-style-type: disc !important;
    padding-left: 5% !important;
    /* border: 2px solid red; */
}

.driver-info-image {
    /* flex: 0 0 auto; */
    width: 45%;
    object-fit: contain;
    display: flex;
    justify-content: center;
    /* border: 2px solid red; */
}

.image-container13 {
    box-sizing: border-box;
    display: block;
    /* width: 745px; */
    max-width: initial;
    /* height: 434px; */
    border: none;
    object-fit: contain;
    width: 100%;
}
.phoneimage{
    width: 60%;
    object-fit: contain;
}
.hub-operations-main-container {
    display: flex;
    width: 100%;
    /* border: 2px solid green; */
    justify-content: center;
}

.hub-operations-dashboard {
    display: flex;
    /* flex: 0 0 auto; */
    flex-direction: row;
    align-items: center;
    /* align-self: stretch; */
    /* justify-content: flex-start; */
    /* padding-right: 200px; */
    /* padding-left: 200px; */
    margin-top: 33px;
    justify-content: space-between;
    width: 90%;
    /* border: 2px solid red; */
}

.hub-ops-dashboard2 {
    box-sizing: border-box;
    flex: 0 0 auto;
    width: 50.86%;
}

.image-container9 {
    box-sizing: border-box;
    display: block;
    /* width: 696px; */
    max-width: initial;
    /* height: 434px; */
    border: none;
    object-fit: contain;
    width: 100%;
}

.hub-ops-dashboard {
    box-sizing: border-box;
    width: 50%;
    /* display: flex;
    justify-content: space-between; */
    /* flex: 0 0 auto; */
    /* width: 49.14%; */
    /* padding: 33px 15px 37px; */
}

.hub-ops-dashboard-title {
    font: 700 52px Outfit;
    color: rgba(6, 55, 103, 1);
    text-align: left;
}

.hub-ops-dashboard1 {
    box-sizing: border-box;
    max-width: 589px;
    padding: 0;
    margin: 32px 0 0;
    font: 400 20px Outfit;
    color: rgba(6, 55, 103, 1);
    text-align: left;
}

.unified-customer-communication-conatiner {
    width: 100%;
    display: flex;
    justify-content: center;
}

.unified-customer-communication {
    display: flex;
    /* flex: 0 0 auto; */
    flex-direction: row;
    align-items: center;
    /* justify-content: flex-end; */
    /* border: 2px solid red; */
    /* padding-right: 200px; */
    /* padding-left: 200px; */
    /* margin-top: -17px; */
    /* border: 2px solid red; */
    width: 90%;
}

.unified-customer-communication1 {
    display: flex;
    /* flex: 0 0 auto; */
    flex-direction: column;
    /* align-items: center; */
    justify-content: flex-start;
    padding-top: 33px;
    padding-bottom: 37px;
    width: 60%;
    /* margin-right: 58px; */
}

.unified-customer-communication2 {
    flex: 0 0 auto;
    /* padding-left: 15px; */
    font: 700 52px Outfit;
    color: rgba(6, 55, 103, 1);
    text-align: left;
}

.image-container3 {
    box-sizing: border-box;
    display: block;
    /* width: 696px; */
    max-width: initial;
    /* height: 434px; */
    border: none;
    object-fit: contain;
}

.advantage-section {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: stretch;
    align-self: stretch;
    justify-content: flex-start;
    padding-right: 300px;
    padding-left: 300px;
    /* margin-top: -56px; */
}

.advantage-title {
    flex: 0 0 auto;
    font: 700 76px Outfit;
    color: rgba(6, 55, 103, 1);
}

.advantage-section1 {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    gap: 80px;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: 20px;
    margin-top: 60px;
}

.integration-card {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 20px;
}

.integration-image {
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    width: 60px;
    max-width: initial;
    height: 60px;
}

.integrations-title {
    flex: 0 0 auto;
    align-self: stretch;
    margin-top: 20px;
    font: 600 16px Outfit;
    color: rgba(6, 55, 103, 1);
}

.third-party-connectors-image {
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    width: 60px;
    max-width: initial;
    height: 61px;
}

.cost-container {
    flex: 0 0 auto;
    padding-right: 21px;
    padding-left: 21px;
}

.image-container11 {
    box-sizing: border-box;
    display: block;
    width: 61px;
    max-width: initial;
    height: 60px;
}

.delivery-info {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
}

.quick-time-to-implement {
    flex: 0 0 auto;
    margin-top: 20px;
    font: 600 16px Outfit;
    color: rgba(6, 55, 103, 1);
    text-align: center;
}

.consultative-approach {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 5px;
    padding-bottom: 20px;
}

.consultative-approach1 {
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    width: 60px;
    max-width: initial;
    height: 54px;
}

.delivery-experience-section6 {
    display: flex;
    /* flex: 0 0 auto; */
    flex-direction: column;
    /* align-items: stretch; */
    /* align-self: stretch; */
    justify-content: flex-start;
    /* border: 2px solid red; */
    width: 90%;
    /* padding-right: 300px; */
    /* padding-left: 300px; */
}

.delivery-section {
    display: flex;
    /* flex: 0 0 auto; */
    flex-direction: row;
    gap: 8px;
    /* align-items: flex-end; */
    justify-content: space-between;
    /* border: 2px solid green; */
    /* margin-right: -161px; */
}

.delivery-info {
    display: flex;
    /* flex: 0 0 auto; */
    flex-direction: column;
    /* align-items: stretch; */
    justify-content: flex-start;
    width: 50%;
}

.enhanced-customer-experience-heading {
    flex: 0 0 auto;
    font: 800 50px Outfit;
    color: rgba(6, 55, 103, 1);
    text-align: left;
}

.enhanced-customer-experience {
    box-sizing: border-box;
    flex: 0 0 auto;
    max-width: 798px;
    margin-top: 30px;
    font: 400 22px Outfit;
    color: rgba(0, 0, 0, 1);
    text-align: left;
}

.delivery-image {
    flex: 0 0 auto;
    padding-top: 22px;
    width: 40%;
}

.image-container5 {
    box-sizing: border-box;
    display: block;
    width: 100%;
    /* width: 546px; */
    /* max-width: initial; */
    /* height: 410px; */
    border: none;
    object-fit: contain;
}

.enhanced-customer-experience-button {
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    width: 266px;
    min-width: 266px;
    height: 67px;
    /* margin-top: 40px; */
    font: 600 28px Outfit;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    background: rgba(0, 150, 213, 1);
    border: none;
    border-radius: 40px;
    /* margin-top: 0px !important; */
}

.whistle-os-main-container {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 5%;
}

.whistle-os-cont {
    width: 80%;
}

.whistle-os-title {

    font: 700 40px Outfit;
    color: rgba(6, 55, 103, 1);
}

.card-container-os{
    display: flex;
    /* justify-content: space-between; */
    /* align-items: center; */
    /* border: 2px solid red; */
    margin-top: 2%;
    flex-wrap: wrap;
    width: 100%;
}

.card-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25%;
    margin-bottom: 2%;
    /* border: 2px solid red; */
}

.card-img{
    width: 50px;
    object-fit: contain;
}

.card-desc2{
    font: 500 20px Outfit;
    color: rgba(6, 55, 103, 1);
    text-align: center;

}

@media screen and (max-width:769px) {
    .card-content{
        width: 30%;
    }
    .card-container-os{
        justify-content: space-between;
        margin-top: 4% !important;
    }
    .whistle-os-title {

        font: 700 24px Outfit !important;
        width: 100%;
        /* color: rgba(6, 55, 103, 1); */
    }
    .card-desc2{
        font: 500 16px Outfit !important;
    }
}