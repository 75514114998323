.feature-grid1 {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  /* align-items: stretch; */
  /* align-self: stretch; */
  /* justify-content: flex-start; */
  align-items: center;
  margin-top: 100px;
}
.feature-grid {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 49px;
  padding-left: 49px;
}
.container-grid {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.main-container-api {
  box-sizing: border-box;
  width: 1000px;
  border-top: 1px solid black;
}
.image-container1 {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: -88.42%;
}
.rounded-container1 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  height: 100px;
  padding-right: 30px;
  padding-left: 30px;
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(0, 0, 0, 1);
  border-radius: 60px;
}
.image-container8 {
  box-sizing: border-box;
  display: block;
  flex: 0 0 auto;
  width: 40px;
  max-width: initial;
  height: 40px;
}
.image-container2 {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 29.94%;
}
.card-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  height: 101px;
  padding-right: 30px;
  padding-left: 30px;
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(0, 0, 0, 1);
  border-radius: 60px;
}
.image-container7 {
  box-sizing: border-box;
  display: block;
  flex: 0 0 auto;
  width: 40px;
  max-width: initial;
  height: 41px;
}
.image-container {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 28.54%;
}
.rounded-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  height: 105px;
  padding-right: 38px;
  padding-left: 38px;
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(0, 0, 0, 1);
  border-radius: 60px;
}
.image-container6 {
  box-sizing: border-box;
  display: block;
  flex: 0 0 auto;
  width: 28px;
  max-width: initial;
  height: 42px;
}
.feature-list {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: row;
  /* gap: 100px; */
  /* align-items: flex-start; */
  justify-content: center;
  /* align-items: center; */
  margin-top: 5px;
  width: 80%;
  /* border: 2px solid red; */
}
.smart-apis-container {
  box-sizing: border-box;
  display: flex;
  /* flex: 1 1 0; */
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  width: 50%;
  /* border: 2px solid red; */
  /* justify-content: flex-start; */
  /* max-width: 200px; */
  /* padding-bottom: 25px; */
}
.smart-apis-title {
  /* flex: 0 0 auto; */
  padding-right: 34px;
  padding-left: 34px;
  font: 600 26px Outfit;
  color: rgba(6, 55, 103, 1);
  text-align: center;
}
.smart-apis-description {
  flex: 0 0 auto;
  /* align-self: stretch; */
  /* margin-top: 49px; */
  font: 300 16px Outfit;
  color: rgba(6, 55, 103, 1);
  text-align: center;
}
.load-plan-container {
  box-sizing: border-box;
  display: flex;
  /* flex: 1 1 0; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* align-items: stretch; */
  /* justify-content: flex-start; */
  max-width: 200px;
  /* padding-bottom: 24px; */
}
.intelligent-load-planning {
  flex: 0 0 auto;
  padding-right: 8px;
  padding-left: 8px;
  font: 600 26px Outfit;
  color: rgba(6, 55, 103, 1);
  text-align: center;
}
.intelligent-load-planning1 {
  flex: 0 0 auto;
  margin-top: 9px;
  font: 300 16px Outfit;
  color: rgba(6, 55, 103, 1);
  text-align: center;
}
.reach-section {
  box-sizing: border-box;
  display: flex;
  /* flex: 1 1 0; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* align-items: stretch; */
  /* justify-content: flex-start; */
  /* max-width: 200px; */
  /* padding-bottom: 45px; */
  border: 2px solid red;
}
.greater-reach-heading {
  /* flex: 0 0 auto; */
  /* padding-right: 13px; */
  /* padding-left: 13px; */
  font: 600 26px Outfit;
  text-align: center;
  color: rgba(6, 55, 103, 1);
}
.reach-description {
  /* flex: 0 0 auto; */
  /* margin-top: 49px; */
  font: 300 16px Outfit;
  color: rgba(6, 55, 103, 1);
  text-align: center;
}
.customer-info {
  box-sizing: border-box;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 200px;
  padding-top: 4px;
}
.informed-customers {
  flex: 0 0 auto;
  padding-right: 36px;
  padding-left: 36px;
  font: 600 26px Outfit;
  color: rgba(6, 55, 103, 1);
  text-align: center;
}
.customer-notification {
  flex: 0 0 auto;
  align-self: stretch;
  margin-top: 9px;
  font: 300 16px Outfit;
  color: rgba(6, 55, 103, 1);
  text-align: center;
}

.icon{
  width: 80px;
  object-fit: contain;
}

@media screen and (max-width:769px) {
  .feature-list{
    flex-direction: column;
    /* border: 2px solid green; */
    align-items: center;
  }
  .feature-grid1{
    margin-top: 40px;
    /* border: 2px solid red; */
  }
  hr{
    rotate: 90deg;
    margin-top: 40px;
    margin-bottom: 40px;

    /* height: 40px !important; */
  }
  .smart-apis-container {
    /* border: 2px solid red; */
    width: 100%;
  }
  
}