.footer-container {
  box-sizing: border-box;
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  /* align-items: stretch; */
  justify-content: center;
  height: 60px;
  /* padding-right: 48px; */
  /* padding-left: 360px; */
  margin-top: 100px;
  background: rgba(5, 22, 38, 1);
  width: 100%;
}
.outfit-text {
  /* flex: 0 0 auto; */
  font: 400 16px Outfit;
  color: rgba(255, 255, 255, 1);
  text-align: center;
}

@media screen and (max-width:769px) {
  .footer-container{
    margin-top: 30px;
  }
  
}
