.delivery-experience-section4 {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  align-items: center;
  /* border: 2px solid blue; */
  width: 100%;
  /* gap: 170px; */
  /* align-items: center; */
  /* justify-content: flex-start; */
}
.delivery-operations {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  margin-top: 5%;
  margin-bottom: 5%;
  /* align-items: center; */
  /* align-self: stretch; */
  /* justify-content: flex-start; */
  /* padding-right: 410px; */
  /* padding-left: 410px; */
}
.delivery-heading {
  flex: 0 0 auto;
  font: 800 46px Outfit;
  color: rgba(6, 55, 103, 1);
  text-align: center;
}
.driver-info-section {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: row;
  /* align-items: flex-start; */
  /* justify-content: flex-end; */
  /* padding-right: 200px; */
  /* padding-left: 200px; */
  /* margin-top: 169px; */
  justify-content: center;
  width: 100%;
  margin-top: 5% !important;
  /* border: 2px solid red; */
}
.driver-info-main-container{
  width: 90%;
  display: flex;
  /* border: 2px solid red; */
}
.driver-info-container {
  box-sizing: border-box;
  display: flex;
  /* flex: 0 1 auto; */
  flex-direction: column;
  /* align-items: stretch; */
  /* justify-content: flex-start; */
  /* max-width: 589px; */
  padding-top: 33px;
  padding-bottom: 12px;
  /* margin-right: 58px; */
  width: 50%;
  /* border: 2px solid yellow; */
}
.app-title {
  flex: 0 0 auto;
  font: 700 52px Outfit;
  color: rgba(6, 55, 103, 1);
  text-align: left;
}
.driver-info-list {
  /* flex: 0 0 auto; */
  /* padding: 0; */
  margin: 32px 0 0;
  font: 400 20px Outfit;
  color: rgba(6, 55, 103, 1);
  /* text-align: left; */
  list-style-type: disc;
  padding-left: 5% !important;
}
.driver-info-image {
  /* flex: 0 0 auto; */
  width: 40%;
}
.image-container13 {
  box-sizing: border-box;
  display: block;
  /* width: 745px; */
  max-width: initial;
  /* height: 434px; */
  border: none;
  object-fit: cover;
}
.hub-operations-main-container{
  display: flex;
  width: 100%;
  /* border: 2px solid green; */
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
}
.hub-operations-dashboard {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: row;
  align-items: center;
  /* align-self: stretch; */
  /* justify-content: flex-start; */
  /* padding-right: 200px; */
  /* padding-left: 200px; */
  margin-top: 33px;
  width: 90%;
  /* border: 2px solid red; */
}
.hub-ops-dashboard2 {
  box-sizing: border-box;
  flex: 0 0 auto;
  width: 50.86%;
}
.image-container9 {
  box-sizing: border-box;
  display: block;
  /* width: 696px; */
  max-width: initial;
  /* height: 434px; */
  border: none;
  object-fit: contain;
}
.hub-ops-dashboard {
  box-sizing: border-box;
  width: 50%;
  /* flex: 0 0 auto; */
  /* width: 49.14%; */
  /* padding: 33px 15px 37px; */
}
.hub-ops-dashboard-title {
  font: 700 52px Outfit;
  color: rgba(6, 55, 103, 1);
  text-align: left;
}
.hub-ops-dashboard1 {
  box-sizing: border-box;
  max-width: 589px;
  /* padding: 0; */
  margin: 32px 0 0;
  font: 400 20px Outfit;
  color: rgba(6, 55, 103, 1);
  list-style-type: disc;
  padding-left: 5% !important;
  /* text-align: left; */
}
.unified-customer-communication-conatiner{
  width: 100%;
  display: flex;
  justify-content: center;
}
.unified-customer-communication {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: row;
  align-items: center;
  /* justify-content: flex-end; */
  /* border: 2px solid red; */
  /* padding-right: 200px; */
  /* padding-left: 200px; */
  /* margin-top: -17px; */
  /* border: 2px solid red; */
  width: 90%;
}
.unified-customer-communication1 {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  padding-top: 33px;
  padding-bottom: 37px;
  width: 60%;
  /* margin-right: 58px; */
}
.unified-customer-communication2 {
  flex: 0 0 auto;
  /* padding-left: 15px; */
  font: 700 52px Outfit;
  color: rgba(6, 55, 103, 1);
  text-align: left;
}
.image-container3 {
  box-sizing: border-box;
  display: block;
  /* width: 696px; */
  max-width: initial;
  /* height: 434px; */
  border: none;
  object-fit: contain;
}
.advantage-section {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  justify-content: flex-start;
  padding-right: 300px;
  padding-left: 300px;
  /* margin-top: -56px; */
}
.advantage-title {
  flex: 0 0 auto;
  font: 700 76px Outfit;
  color: rgba(6, 55, 103, 1);
}
.advantage-section1 {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  gap: 80px;
  align-items: flex-start;
  justify-content: space-between;
  padding-right: 20px;
  margin-top: 60px;
}
.integration-card {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 20px;
}
.integration-image {
  box-sizing: border-box;
  display: block;
  flex: 0 0 auto;
  width: 60px;
  max-width: initial;
  height: 60px;
}
.integrations-title {
  flex: 0 0 auto;
  align-self: stretch;
  margin-top: 20px;
  font: 600 16px Outfit;
  color: rgba(6, 55, 103, 1);
}
.third-party-connectors-image {
  box-sizing: border-box;
  display: block;
  flex: 0 0 auto;
  width: 60px;
  max-width: initial;
  height: 61px;
}
.cost-container {
  flex: 0 0 auto;
  padding-right: 21px;
  padding-left: 21px;
}
.image-container11 {
  box-sizing: border-box;
  display: block;
  width: 61px;
  max-width: initial;
  height: 60px;
}
.delivery-info {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.quick-time-to-implement {
  flex: 0 0 auto;
  margin-top: 20px;
  font: 600 16px Outfit;
  color: rgba(6, 55, 103, 1);
  text-align: center;
}
.consultative-approach {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 5px;
  padding-bottom: 20px;
}
.consultative-approach1 {
  box-sizing: border-box;
  display: block;
  flex: 0 0 auto;
  width: 60px;
  max-width: initial;
  height: 54px;
}
@media screen and (max-width:769px) {
  .driver-info-list{
    font: 400 18px Outfit !important;
  }
  
}