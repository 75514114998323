.privacy-policy-container {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  gap: 20px;
  /* align-items: stretch; */
  /* align-self: stretch; */
  justify-content: flex-start;
  margin-top: 40px;
}
.form-container {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  /* border: 2px solid red; */
}
.rounded-input {
  box-sizing: border-box;
  display: flex;
  width: 50%;
  /* flex: 0 0 auto; */
  flex-direction: row;
  align-items: center;
  justify-content: start;
  /* min-width: 171px; */
  height: 51px;
  padding-left: 26px;
  font: 400 18px Outfit;
  color: rgba(6, 55, 103, 1);
  border: 1.5px solid rgba(6, 55, 103, 1);
  border-radius: 100px;
  opacity: 0.7;
}
.transparent-input {
  box-sizing: border-box;
  width: 100%;
  font: 400 18px Outfit;
  background: transparent;
  border: none;
  outline: none;
  opacity: 0.7;
}
.input-style-90e37cfe::placeholder {
  color: rgba(6, 55, 103, 1);
}
.rounded-input2 {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  /* min-width: 170px; */
  height: 51px;
  padding-left: 26px;
  font: 400 18px Outfit;
  color: rgba(6, 55, 103, 1);
  border: 1.5px solid rgba(6, 55, 103, 1);
  border-radius: 100px;
  opacity: 0.7;
  width: 50%;
}
.rounded-input1 {
  box-sizing: border-box;
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: row;
  align-items: center;
  justify-content: start;
  height: 51px;
  padding-left: 26px;
  font: 400 18px Outfit;
  color: rgba(6, 55, 103, 1);
  border: 1.5px solid rgba(6, 55, 103, 1);
  border-radius: 100px;
  opacity: 0.7;
}
.privacy-policy-text {
  flex: 0 0 auto;
  margin-top: -8px;
  font: 400 16px Outfit;
  color: rgba(6, 55, 103, 1);
}
.meeting-button {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex: 0 0 auto; */
  width: 186px;
  min-width: 186px;
  height: 49px;
  font: 500 20px Outfit;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  background: rgba(6, 55, 103, 1);
  border: none;
  border-radius: 100px;
  text-align: center;
}

.errorMsg{
  font: 500 20px Outfit;
  color: red;
  padding: 0px;
  margin: 0px;
}
