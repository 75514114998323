.supply-chain-demo-section {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  /* align-items: center; */
  /* align-self: stretch; */
  justify-content: center;
  align-items: center;
  /* padding: 63px 308px 73px; */
  /* margin-top: -7px; */
  background: rgba(242, 244, 255, 1);
  /* border: 2px solid red; */
  width: 100%;
  padding-top: 2%;
  padding-bottom: 2%;
}

.demo-container {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: flex-start;
}

.signup-title {
  /* flex: 0 0 auto; */
  /* padding-right: 96px; */
  /* padding-left: 93px; */
  font: 700 50px Outfit;
  color: rgba(6, 55, 103, 1);
}

.signup-description {
  /* flex: 0 0 auto; */
  /* align-self: stretch; */
  margin-top: 20px;
  width: 60%;
  text-align: center;
  font: 400 20px Outfit;
  color: rgba(22, 22, 22, 1);
}

.signup-button {
  box-sizing: border-box;
  display: block;
  flex: 0 0 auto;
  width: 216px;
  min-width: 216px;
  height: 57px;
  margin-top: 49px;
  font: 600 20px Outfit;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  background: rgba(0, 150, 213, 1);
  border: none;
  border-radius: 40px;
}

@media screen and (max-width:769px) {
  .signup-button {
    width: 160px !important;
    min-width: 160px !important;
    height: 50px !important;
    font: 600 16px Outfit !important;
    margin-top:20px;

  }

  .signup-title {
    font: 700 32px Outfit;
    text-align: center;
    width: 90%;

  }

  .signup-description {
    width: 80%;
    text-align: center;
    font: 400 18px Outfit;
  }


}