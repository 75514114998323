.tech-platform-container3 {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  /* align-items: stretch; */
  justify-content: flex-start;
  width: 100%;
}
.os-title {
  /* flex: 0 0 auto; */
  font: 700 52px Outfit;
  color: rgba(6, 55, 103, 1);
  text-align: left;
}
.tech-platform-description {
  /* flex: 0 0 auto; */
  margin-top: 20px;
  font: 500 20px Outfit;
  color: rgba(6, 55, 103, 1);
  text-align: left;
}

@media screen and (max-width:769px) {
  .os-title{
    font: 700 38px Outfit;

  }
  .tech-platform-description{
    font: 500 18px Outfit;
    
  }
  
}
