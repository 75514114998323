.dispatch-management-platform {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  align-items: center;
  /* align-self: stretch; */
  justify-content: flex-start;
  margin-top: 40px;
  /* border: 2px solid red; */
  width: 60%;
}
.dispatch-management {
  box-sizing: border-box;
  /* flex: 0 0 auto; */
  /* max-width: 787px; */
  color: rgba(5, 71, 121, 1);
  text-align: center;
}
.dispatch-title {
  font: 500 20px Outfit;
  color: rgba(0, 150, 213, 1);
  text-align: center;
}
.dispatch-management-text {
  font: 400 20px Outfit;
  text-align: center;
}
.dispatch-platform-description {
  box-sizing: border-box;
  /* flex: 0 0 auto; */
  /* max-width: 946px; */
  margin-top: 20px;
  font: 400 20px Outfit;
  color: rgba(17, 17, 17, 1);
  text-align: center;
}

@media screen and (max-width:769px) {
  .dispatch-management-platform {
    margin-top: 20px;
    width: 100%;
  }
  .dispatch-title {
    font: 500 16px Outfit;
  }
  .dispatch-management-text {
    font: 400 16px Outfit;
  }
  .dispatch-platform-description {
    font: 400 16px Outfit;
  }
  
}
