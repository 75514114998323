.industry-type-container {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: row;
  /* border: 2px solid red; */
  align-items: flex-start;
  justify-content: space-between;
  width: 40%;
  /* align-items: center; */
  /* justify-content: flex-start; */
}
.services-container {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  /* border: 2px solid red; */
  width: 50%;
  /* align-items: stretch; */
  /* justify-content: center; */
}
.service-title {
  flex: 0 0 auto;
  font: 600 20px Outfit;
  color: rgba(255, 255, 255, 1);
}
.company-info {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  /* align-items: stretch; */
  /* justify-content: center; */
  /* border: 2px solid green; */
  /* margin-left: 131px; */
}
.company-title {
  flex: 0 0 auto;
  font: 600 20px Roboto;
  color: rgba(255, 255, 255, 1);
}
.transportation-section {
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  /* align-items: stretch; */
  /* justify-content: center; */
  margin-top: 6px;
}
.about-us-text {
  flex: 0 0 auto;
  padding-right: 21px;
  font: 300 18px Outfit;
  color: rgba(237, 237, 237, 1);
}
.employee-transportation {
  flex: 0 0 auto;
  margin-top: 4px;
  font: 300 18px Outfit;
  color: rgba(237, 237, 237, 1);
}

.page-title-container {
  box-sizing: border-box;
  flex: 0 0 auto;
  width: 55.92%;
}
.page-title:first-child {
  margin-top: 0px;
}
.page-title {
  font: 300 18px Outfit;
  color: rgba(237, 237, 237, 1);
  white-space: pre-wrap;
}
.page-title1 {
  margin-top: 4px;
}

@media screen and (max-width:769px) {
  .industry-type-container{
    width: 100%;
  }
  .company-info{
    width: 50%;
  }
  
}